<template>
    <div>
        <button @click="uploadCogs" class="btn btn-primary margin_bottom">Upload Curb Cost of Goods Data</button>
        <div class="well well-lg">
            <strong>Instructions</strong><br />
            <p>Construct a .csv file with new lines for each curb in the following format:</p>
            <p>Curb ID, Crated Length, Crated Width, Crated Height, Metal Weight, Insulation Sqft, 1.25" Angle Iron, 2" Angle Iron, Gasket (# Rolls), 2x4x12, labor hours</p>
            <p>The website will parse your data and then ask for your review before applying the changes.</p>
        </div>
    </div>

</template>

<script>

// import { store } from '@/store/BusinessObject.store'

export default {
    name: 'cogs_upload',
    data: function() {
        return {
            // state: store.state;
        }
    },
    methods: {
        uploadCogs: function() {
            this.$appStore.setFileUploadContext('cogs');
            this.$appStore.setFileUploadContextData({
                /*
                This doesn't technically have a referenceID.
                Just setting one to activate the "Upload File Button"
                */
                referenceID: 1
            });
            this.$appStore.setFileUploadCallback(function () {});
        }
    }
}

</script>